<template>
<div class="shopping-info">
    <div class="shopping" :class="position">
        <p v-if="pack?.displayEmailSignup" class="email-signup release-date">
            {{ `${$t('PACK_DETAIL.ARRIVING_ON')} ${getReleaseDate()}` }}
        </p>
        <h1 v-if="heading">
            <img
                v-if="pack" :src="`${pack?.wordmark?.data?.attributes.url}?format=auto`" role="button"
                :alt="pack?.wordmark?.data?.attributes.alternativeText" tabindex="0" class="wordmark"
                :class="{clickable: clickableWordmark}" v-on:click="navigateToPackDetails(pack.slug)"
                v-on:keydown.enter="navigateToPackDetails(pack.slug)"
            >
        </h1>
        <div v-else class="logo-container">
            <img
                v-if="pack" :src="`${pack?.wordmark?.data?.attributes.url}?format=auto`" role="button"
                :alt="pack?.wordmark?.data?.attributes.alternativeText" tabindex="0" class="wordmark"
                :class="{clickable: clickableWordmark}" v-on:click="navigateToPackDetails(pack.slug)"
                v-on:keydown.enter="navigateToPackDetails(pack.slug)"
            >
        </div>

        <template v-if="pack?.displayEmailSignup">
            <p class="reminder">
                {{ $t('FORM.REMINDER') }}
            </p>
            <EmailSignup />
        </template>

        <template v-if="pack && pack.displayWishlistUI">
            <div class="wishlist-button-block">
                <!-- TODO: <p class="short-description" /> -->
                <div class="notes">
                    <span v-if="pack && pack.matureRating" class="rating">
                        <img
                            v-if="rating === 'ESRB'" class="m-rating icon" src="/images/ratings/ESRB-little.png"
                            alt="Rated M for mature"
                        >
                        <p class="rating text">{{ `RATED ${ageRating}` }}</p>
                    </span>
                    <span v-else class="steam">
                        <img
                            class="steam-code icon" alt="steam icon"
                            src="/images/icons/steam-code-icon-white-circle.svg"
                        >
                        <p class="steam-code text">For <b>STEAM </b></p>
                    </span>
                    <p class="wishlist release-date"><i>{{ getReleaseDate() }}</i></p>
                </div>
                <button
                    class="primary-gradient wide icon-button" v-on:click="wishlistOnSteam"
                    v-on:keydown.enter="wishlistOnSteam"
                >
                    <p>{{ $t('HOME.WISHLIST') }}</p>
                    <div class="icons">
                        <img src="/images/icons/windows.svg" alt="Windows" class="windows">
                        <img src="/images/icons/apple.svg" alt="Mac" class="apple">
                        <img src="/images/icons/linux.svg" alt="Linux" class="linux">
                    </div>
                </button>
                <button
                    class="outline wide icon-button" v-on:click="navigateToBlog"
                    v-on:keydown.enter="navigateToBlog"
                >
                    <p>{{ $t('GAME_DETAIL.LEARN_MORE') }}</p>
                    <div class="icons">
                        <img src="/images/icons/arrow-right.svg" alt="->" class="arrow-right">
                    </div>
                </button>
            </div>
        </template>

        <template v-else>
            <div v-if="product" class="item-container">
                <div class="price-info">
                    <span class="steam">
                        <img
                            class="steam-code icon" alt="steam icon"
                            src="/images/icons/steam-code-icon-white-circle.svg"
                        >
                        <p class="steam-code text"><b>STEAM </b>CODE</p>
                    </span>
                    <div class="price">
                        <p
                            v-if="compareAtPrice && discounted" class="item paragraph-s"
                            :class="{ strikethrough: discounted}"
                        >
                            {{ getCurrency(currencyCode) }}{{ compareAtPrice }}
                        </p>
                        <p v-if="discounted && price && compareAtPrice" class="item sale">
                            {{ Math.round(((price - compareAtPrice) / compareAtPrice) * 100) }}%
                        </p>
                        <p v-if="price" class="paragraph item right price">
                            {{ getCurrency(currencyCode) }}{{ price }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="pack" class="btn-container">
                <AddToCartButton :product="product" :pack="pack" />
                <StorefrontDropdown
                    v-if="platformOptions && platformOptions.length > 0"
                    class="secondary other-plats" :options="platformOptions" :pack="pack"
                    :product="product"
                    :placeholder="$t('PACK_DETAIL.BUY_OTHER_PLATS')" :fell-back-to-en="fellBackToEn"
                    :is-bundle="isBundle" :is-localized="isLocalized"
                />
            </div>
            <StorefrontIcons
                v-if="platformOptions && platformOptions.length > 0" :pack="pack"
                :platform-options="platformOptions" :fell-back-to-en="fellBackToEn"
                :is-bundle="isBundle" :is-localized="isLocalized"
            />
        </template>
    </div>
</div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { Shopify } from '@jackboxgames/shopify'

import { getCurrencySymbol, getStorefrontsFromProductData, getStorefrontLink } from '$services/storefronts/storefronts'

import AddToCartButton from '$components/shopping/AddToCartButton.vue'
import ratingsRegionMapJson from '$components/ratings/ratingsRegionMap.json'
import type { JBGWeb } from '$types/JBGWeb'

import StorefrontDropdown from './StorefrontDropdown.vue'
import EmailSignup from '../forms/EmailSignup.vue'
import StorefrontIcons from './StorefrontIcons.vue'

export default defineNuxtComponent({
    components: {
        AddToCartButton,
        StorefrontDropdown,
        EmailSignup,
        StorefrontIcons
    },

    props: {
        pack: Object as PropType<JBGWeb.PackDetail>,
        product: Object as PropType<Shopify.Product>,
        slug: String,
        clickableWordmark: Boolean,
        showPlatforms: Boolean,
        heading: {
            type: Boolean,
            default: true,
            required: false
        },
        fellBackToEn: {
            type: Boolean,
            default: false,
            required: false
        },
        isBundle: {
            type: Boolean,
            default: false,
            required: false
        },
        isLocalized: {
            type: Boolean,
            default: false,
            required: false
        },
        position: {
            type: String,
            required: false
        }
    },

    data() {
        return {
            isLoading: false,

            price: null as number | null,
            currencyCode: '',
            compareAtPrice: null as number | null,
            rating: 'ESRB', // default to ESRB

            windowWidth: window?.innerWidth
        }
    },

    computed: {
        discounted() {
            if (!this.price) return false
            if (!this.compareAtPrice) return false
            if (this.price < this.compareAtPrice) {
                return true
            }
            return false
        },

        ageRating() {
            switch (this.rating) {
            case 'ACB':
                return '15+'
            case 'RARS':
                return '18+'
            case 'USK':
            case 'PEGI':
            case 'ClassInd':
                return '16+'
            default: return 'M 17+'
            }
        },

        platformOptions() {
            if (!this.showPlatforms) return
            if (!this.product) return

            return getStorefrontsFromProductData(this.product)
        }
    },

    mounted() {
        this.getBrowserLocale()
        if (!this.product) return console.warn('no product provided to component.')
        this.compareAtPrice = Number(this.product.compareAtPriceRange.maxVariantPrice.amount)
        this.price = Number(this.product.priceRange.minVariantPrice.amount)
        this.currencyCode = this.product.priceRange.minVariantPrice.currencyCode
    },

    // NOTE: this check is duplicating the check in mounted to ensure pricing vars can be set for standalone games pages.
    updated() {
        if (!this.product) return console.warn('no product provided to component.')
        this.compareAtPrice = Number(this.product.compareAtPriceRange.maxVariantPrice.amount)
        this.price = Number(this.product.priceRange.minVariantPrice.amount)
        this.currencyCode = this.product.priceRange.minVariantPrice.currencyCode
    },

    methods: {
        getBrowserLocale() {
            const browserLocale = window.navigator.language
            if (browserLocale.indexOf('-') === -1) return console.warn('browser locale contains no hyphen.')
            const locKey = browserLocale.split('-')[1]
            Object.entries(ratingsRegionMapJson).forEach((rating) => {
                if (rating[1].includes(locKey)) {
                    this.rating = rating[0]
                }
            })
        },

        getCurrency(code:string) {
            return getCurrencySymbol(code)
        },

        getReleaseDate() {
            // funky UTC stuff to get the right date and also give it a localized format
            const date = new Date(this.pack?.releaseDate)
            const timeDiff = date.getTimezoneOffset() * 60000
            const adjustedDate = new Date(date.valueOf() + timeDiff)

            // If date is way in the future only show month as a hack
            // to allow us to show a month when we don't know the exact date
            if (timeDiff < 31_556_952_000) {
                return adjustedDate.toLocaleDateString(this.$i18n.locale, { month: 'long' })
            }

            return adjustedDate.toLocaleDateString(this.$i18n.locale, { month: 'long', day: 'numeric', year: 'numeric' })
        },

        navigateToPackDetails(slug: string) {
            if (!this.clickableWordmark) return
            if (!slug) return console.warn('could not find slug on pack')
            // I defined the routes this way for now to appease a commit-time ts error where
            // it didn't know the type of the contents in localeRoute
            const packRoute: string = this.localeRoute({
                name: 'games-packs-packslug',
                params: {
                    packslug: slug
                }
            })
            const bundleRoute: string = this.localeRoute({
                name: 'games-bundles-bundleslug',
                params: {
                    bundleslug: slug
                }
            })
            if (!this.isBundle) {
                void this.$router.push(packRoute)
            } else {
                void this.$router.push(bundleRoute)
            }
        },

        navigateToBlog() {
            if (this.pack?.slug === 'the-jackbox-survey-scramble') {
                const y = document.getElementById('more')?.getBoundingClientRect().top ?? 0
                // subtract 100px to account for the header
                window.scrollTo({ top: y - 100, behavior: 'smooth' })
                return
            }

            // WARN: This is currently hardcoded to NP blog. Eventually it should navigate to a details page, I'd think?
            window.open('https://www.jackboxgames.com/blog/wishlist-the-jackbox-naughty-pack-on-steam', '_self')
        },

        wishlistOnSteam() {
            const link = getStorefrontLink(this.$i18n.locale, this.pack.slug, 'steam')
            window.open(link, '_blank')
        }
    }
})
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.form-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
}

.email-signup.release-date {
    margin: auto;
    font-family: Inter;
    font-size: 18px;
    font-style: italic;
    font-weight: 600;
    line-height: 20px; /* 100% */
    letter-spacing: 2.4px;
    padding-bottom: 12px;
    text-transform: uppercase;
}

.reminder {
    font-family: Inter;
    font-size: 16px;
    padding-top: 24px;
    margin-bottom: 0px;

    @include mq-medium-and-more {
        width: 100%;
    }

    @include mq-medium-and-less {
        padding-top: 6px;
    }
}


.shopping-info {
    position: relative;
    z-index: 1;

    @include mq-xsmall {
        align-self: flex-end;
    }

    .shopping {
        background: var(
        --pack-details-linear-bg,
        linear-gradient(180deg, rgba(20, 22, 28, 0.68) 26.56%, rgba(26, 31, 38, 0.68) 100%)
        );
        box-shadow: 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px 0px rgba(0, 0, 0, 0.20), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(16px);

        align-items: stretch;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        padding: 16px 24px;

        .item-container {
            margin: 20px -8px 0px 0px;
            @include mq-xsmall {
                margin: 0;
                padding-top: 24px;
            }
            .price-info {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .price {
                    align-self: center;
                    @include mq-xsmall {
                        display: flex;
                        align-items: flex-end;
                        gap: 8px;
                    }
                }
                .steam, .rating {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 6px;
                    position: relative;
                    .icon {
                        height: 14px;
                    }
                    .text {
                        font-size: 12px;
                        font-style: normal;
                        line-height: 12px;
                        position: relative;
                        top: 6px;
                    }
                }
            }
        }

        h1 {
            margin: 0;
            text-align: center;
        }

        .wordmark {
            max-width: 100%;
            &.clickable {
                cursor: pointer;
                margin-bottom: 20px;

                &:hover {
                    transition: transform .2s;
                    transform: scale(1.05, 1.05);
                }
            }

            @include mq-xsmall {
                width: 92vw;
            }
        }

        @include mq-large{
            width: 420px;
        }

        @include mq-medium {
            width: 364px;
        }

        @include mq-small {
            width: 320px;
        }

        @include mq-xsmall {
            background: none;
            box-shadow: none;
            backdrop-filter: none;
            border-radius: 0;
            padding: 0 12px;
        }

        .price {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 700;
            line-height: 14px;

            @include mq-small-and-less {
                font-size: 12px;
                line-height: 12px;
            }
        }

        .logo-container {
            text-align: center;
        }

        .btn-container {
            display: flex;
            flex-direction: column;
        }

        .plat-icons-container {
            display: flex;
            justify-content: space-around;
            margin-top: 16px;

            @include mq-small-and-less {
                margin-top: 12px;
            }

            img {
                cursor: pointer;

                @include mq-small-and-less {
                    height: 18px;
                    width: 18px;
                }
            }
        }

        .wishlist-button-block {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .notes {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;

                .rating, .steam {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    margin: 0;

                    img {
                        height: 18px;
                        width: 18px;
                        position: relative;
                        top: -2px;
                    }

                    p {
                        text-transform: uppercase;
                        margin: 0;
                        font-size: 12px;
                        line-height: 12px;
                    }
                }

                .wishlist.release-date {
                    display: flex;
                    align-items: center;
                    margin: 0;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-align: right;
                    position: relative;
                    top: -1px;
                }
            }

            button {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 48px;
                font-size: 16px;
                line-height: 16px;
                margin: 0;
                text-align: left;

                p {
                    margin: 0;
                }

                &.primary-gradient {
                    font-weight: 800;
                }

                &.outline {
                    margin-bottom: 10px;

                    &:hover {
                        border: 2.5px solid var(--primary-200);
                        color: var(--primary-200);

                        .arrow-right {
                            filter: brightness(0) saturate(100%) invert(82%) sepia(30%)
                            saturate(3659%) hue-rotate(191deg) brightness(103%) contrast(101%);
                        }
                    }
                }

                @include mq-small-and-less {
                    font-size: 14px;
                    line-height: 14px;
                    width: 280px
                }

                &.icon-button {
                    .icons {
                        display: flex;
                        gap: 8px;

                        img {
                            &.windows {
                                height: 16px;
                                width: 17px;
                            }

                            &.apple {
                                height: 16px;
                                width: 14px;
                            }

                            &.linux {
                                height: 18px;
                                width: 15px;
                            }

                            &.arrow-right {
                                height: 18px;
                                width: 15.429px;
                            }

                            @include mq-small-and-less {
                                &.windows {
                                    width: 15px;
                                    height: 14px;
                                }

                                &.apple {
                                    width: 12px;
                                    height: 14px;
                                }

                                &.linux {
                                    height: 16px;
                                    width: 13px;
                                }

                                &.arrow-right {
                                    height: 16px;
                                    width: 13.715px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.theme-jbss {
    button.primary-gradient {
        background: linear-gradient(90deg, #FAD102 0%, #FFF16E 100%);

        &:hover:not(:disabled) {
            box-shadow:
                0px 0px 26.006px 0px rgb(255, 241, 110, 0.5),
                0px 0px 1.238px 0px rgb(255, 241, 110, 0.5),
                0px 0px 0.619px 0px rgb(255, 241, 110, 0.5);
        }
    }

    .shopping .wishlist-button-block button.outline {
        .arrow-right {
            transform: rotate(90deg);
        }

        &:hover {
            border: 2.5px solid #FAD102;
            color: #FAD102;

            .arrow-right {
                filter: brightness(0) saturate(100%) invert(82%) sepia(30%)
                saturate(3659%) hue-rotate(0) brightness(103%) contrast(101%);
            }
        }
    }
}
</style>
