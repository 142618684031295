<!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
<template>
<div
    v-if="pack && pack?.slug !== 'ydkj-classic'" class="custom-dropdown" :class="{error: error}"
    :tabindex="tabindex"
    v-on:blur="open = false"
>
    <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
    <div
        class="selected paragraph regular"
        :class="{open: open}"
        tabindex="0"
        v-on:click="open = !open"
        v-on:keydown.enter="open = !open"
    >
        {{ labelText && labelText }} {{ selected.text }}
        <img class="arrow-icon" src="../../assets/icons/chevron.svg" alt="" aria-hidden>
    </div>

    <div v-if="pack" class="items" :class="{selectHide: !open}">
        <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
        <div
            v-for="(option, i) of options"
            :key="i"
            class="item paragraph external-storefront"
            tabindex="0"
            :class="[option.vendor, getProductClass(pack.slug)]"
            v-on:click="handleChange(option)"
            v-on:keydown.enter="handleChange(option)"
        >
            <NuxtLink
                v-if="option"
                :href="storefrontLink(pack.slug, option.vendor)?.toString()"
                target="_blank"
                :aria-label="`link to ${option.text} storefront`"
                tabindex="0"
                class="external-storefront item"
                :class="getProductClass(pack.slug)"
                external
            >
                <div class="flex-container">
                    <div class="flex-row">
                        <img
                            v-if="option.icon"
                            :src="`/images/icons/platforms/${option.icon}.svg`"
                            alt=""
                            class="plat-icon"
                        >
                        <div class="dropdown-text">
                            {{ option.text }}
                            <div v-if="option.price">{{ getCurrency(option.currencyCode) }}{{ option.price }}</div>
                        </div>
                    </div>
                    <div>
                        <div v-if="discounted(option)" class="sale"> {{ $t('PACK_DETAIL.ON_SALE') }} </div>
                    </div>
                </div>
            </NuxtLink>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import type { Shopify } from '@jackboxgames/shopify'
import { defineComponent, type PropType } from 'vue'
import { getStorefrontLink, getCurrencySymbol, getProductGTMClass } from '$services/storefronts/storefronts'
import type { JBGWeb } from '$types/JBGWeb'
import { Geolocate } from '$services/geolocate'

export default defineComponent({
    props: {
        options: {
            type: Array as PropType<JBGWeb.DropdownOption[]>,
            required: true
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Select an option'
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0
        },
        labelText: {
            type: String,
            required: false
        },
        error: {
            type: Boolean,
            required: false,
            default: false
        },
        icon: {
            type: String,
            required: false,
            default: null
        },
        pack: {
            type: Object as PropType<JBGWeb.PackDetail>
        },
        product: {
            type: Object as PropType<Shopify.Product>
        },
        fellBackToEn: {
            type: Boolean,
            required: false,
            default: false
        },
        isBundle: {
            type: Boolean,
            required: false,
            default: false
        },
        isLocalized: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    emits: ['dropdownChange'],

    data() {
        let selected
        if (this.placeholder) {
            selected = { text: this.placeholder, value: null }
        } else {
            selected = this.options[0]
        }

        return {
            selected,
            open: false
        }
    },

    computed: {
        cvc() {
            return Geolocate.getCountryCode()
        }
    },

    watch: {
        // if the cloudfront-viewer-country cookie changes, reload the app.
        cvc() {
            reloadNuxtApp()
        }
    },

    mounted() {
        this.$emit('dropdownChange', this.selected)
    },

    methods: {
        discounted(option: JBGWeb.DropdownOption) {
            if (!this.product) return false
            if (!this.product.priceRange) return false
            const maxPrice = this.product.compareAtPriceRange.maxVariantPrice.amount
            if (option.price < maxPrice) {
                return true
            }
            return false
        },

        handleChange(option: JBGWeb.DropdownOption) {
            this.selected = option
            this.open = false
            this.$emit('dropdownChange', option.text)
        },

        getCurrency(currencyCode: string) {
            return getCurrencySymbol(currencyCode)
        },

        getProductClass(packSlug: string) {
            return getProductGTMClass(packSlug)
        },

        storefrontLink(slug: string, platform: string): URL | undefined {
            if (!platform || !slug) return undefined
            if (!this.pack) return undefined
            const { locale } = useI18n()
            return getStorefrontLink(locale.value, slug, platform, this.fellBackToEn, this.isBundle, this.isLocalized)
        }
    }
})
</script>

<style lang="scss" scoped>
.custom-dropdown {
    z-index: 1;

    a {
        color: inherit;
        display: block;
        text-decoration: inherit;
        width: 100%;
    }
}
</style>
